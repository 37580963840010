import it from '../assets/img/it-vacancy.png'
import marketing from '../assets/img/marketing-vacancy.png'
import finance from '../assets/img/finance-vacancy.png'

export const type = [
  {
    id: 1,
    locale: {
      slug: 'it',
      image: {
        preview: it
      },
      preview_text: 'Развивайте проекты и управляйте ими: вас ждут интересные задачи по тестированию, дизайну, аналитике и разработке',
      title: 'Работа в IT',
      date: '29-03-2021',
    },
  },
  {
    id: 2,
    locale: {
      slug: 'it',
      image: {
        preview: marketing
      },
      preview_text: 'Вакансии для опытных бухгалтеров, маркетологов, юристов, управленцев, менеджеров B2B и инвестиций',
      title: 'Работа в продажах и в маркетинге',
      date: '29-03-2021',
    },
  },
  {
    id: 3,
    locale: {
      slug: 'it',
      image: {
        preview: finance
      },
      preview_text: 'Консультируйте клиентов, продавайте или доставляйте банковские продукты. Без опыта и по всей России',
      title: 'Работа в финансах',
      date: '29-03-2021',
    },
  },
];

export default type;