<template>
  <header :style="`background:${background};`">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-xl-5 col-ml-5 col-lg-5 col-md-5 header_bg">
          <div class="row">
            <div class="header_title col-xl-10" :style="{ paddingTop, paddingBottom }">
              <!--  -->
              <h1 :class="{'fontAdd': fontWrap }" :style="{'color':textColor}">{{ heading }}</h1>
              <!-- Зарегистрируйтесь на сайте все за несколько минут и получите доступ к товарам в рассрочку. -->
              <p :style="{'color':textColor}">
                {{ title }}
              </p>
              <!-- Пройти регистрацию -->
              <p-button :to="{ name: 'auth' }">{{ btnText }}</p-button>
            </div>
          </div>
        </div>
        <div :class="absoluteImg">
          <div :class="{ header_img: true, addons: hasImageStyleAddons }">
            <img :src="img" alt="" />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import VueTypes from "vue-types";

export default {
  props: {
    background: VueTypes.string,
    heading: VueTypes.string,
    title: VueTypes.string,
    img: VueTypes.string,
    btnText: VueTypes.string,
    btnTo: VueTypes.string,
    paddingTop: VueTypes.string.def("148px"),
    paddingBottom: VueTypes.string.def("149px"),
    textColor: VueTypes.string.def("$black"),
    hasImageStyleAddons: VueTypes.bool.def(false),
    fontWrap: VueTypes.bool.def(false),
    absoluteImg: VueTypes.string.def(false)
  }
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}

.row {
  flex-wrap: nowrap;

  @media (max-width: $mobile) {
    flex-wrap: wrap;
  }
}

.absolute-image {
  align-self: flex-end;
  overflow: hidden;

  @media (max-width: $mobile) {
    align-self: unset;
  }

  div {
    height: 465px;
    width: 696px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

      @media (max-width: $mobile) {
        object-fit: unset;
      }
    }
  }
}

.absolute-image-bonus {
  align-self: flex-end;
  overflow: hidden;

  div {
    height: 514px;
    width: 696px;

    img {
      margin-top: 20px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.jcfe {
  justify-content: flex-end;
}


.df {
  display: flex;
  flex-direction: column-reverse;
}

.image-container {
  padding-right: 0;
  padding-left: 0;
}

img {
  max-width: 100%;
  height: auto;
}


header {

  .header_bg {
    background: url("../assets/icons/Vector.svg") no-repeat right 48px;
    padding-right: 0;
    padding-left: 0;
  }

  .header_img {
    &.addons {
      /*margin: 20% 0 0 60%;*/
      @media (max-width: $mobile) {
        margin: 0;
      }
    }
  }

  .header_title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /*padding-top: 148px;*/
    /*padding-bottom: 149px;*/
    width: 100%;

    h1 {
      font-family: 'TT Travels Trl', sans-serif;
      font-weight: 700;
      font-size: 40px;
      line-height: 40px;
      color: $black;
      margin-bottom: 16px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: $black;
      margin-bottom: 32px;
    }

    a.btn_header {
      background: $main;
      box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
      border-radius: 14px;
      width: auto;
      padding: 18px 24px;
      font-weight: 600;
      font-size: 18px;
      line-height: 111.63%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      transition: 0.2s;

      &:hover {
        background: $main-light;
      }
    }
  }
}


@media (max-width: 1199px) {
  header .header_title {
    padding-bottom: 70px !important;
  }
  header .header_img {
    width: 100%;
    height: 100%;
    align-items: flex-end;
  }
}

@media (max-width: 991px) {
  header .header_title {
    padding-top: 50px;
    padding-bottom: 40px;

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 575px) {
  .absolute-image-bonus {
    margin-top: 0;
  }
  header {
    .header_bg {
      background: none;
      padding-right: 15px;
      padding-left: 15px;
    }

    .header_title {
      padding-top: 45px !important;
      padding-bottom: 24px !important;

      h1 {
        font-weight: 900;
        font-size: 24px;
        line-height: 30px;
        color: $black;
        margin-bottom: 8px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: $black;
        margin-bottom: 24px;
      }
    }
  }
}

.image-container {
  /*margin-right: 20px;*/
}

.fontAdd {
  white-space: nowrap;
}
</style>
