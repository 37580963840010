<template>
  <div class="vacancies">
    <Header
      background="transparent"
      :heading="'Создавайте будущее вместе с нами'"
      :title="'Присоединяйтесь к нашей команде: мы создаем финтех-сервисы для 15 млн клиентов и опережаем рынок на 5 лет. Работаем на результат и делаем больше, чем от нас ждут'"
      :img="vacancyBg"
      :btnText="'Смотреть вакансии'"
      btnTo="/sign-up"
      padding-top="165px"
      padding-bottom="166px"
    />
    <section class="types">
      <div class="container">
        <h2>Вакансии</h2>
        <p class="text-center">Выбирайте работу, которая вам по душе</p>
        <div class="row">
          <div
            class="col-xl-4 col-ml-4 col-lg-6 col-sm-6 mb-32"
            v-for="(newsData, i) in vacancyTypes"
            :key="i"
          >
            <card-new
              class="newsCard"
              :id="newsData.id"
              :slug="newsData.locale.slug"
              :image="newsData.locale.image"
              :heading="newsData.locale.preview_text"
              :title="newsData.locale.title"
              :date="newsData.date"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="info container">
      <div class="row card tech-leader">
        <div class="col-md-6">
          <div class="d-flex">
            <div class="icon bg-main p-14">
              <svg width="45" height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M13.3333 18.0893C13.3333 18.5588 12.96 18.9384 12.5 18.9384C12.04 18.9384 11.6667 18.5588 11.6667 18.0893C11.6667 17.6197 12.04 17.2402 12.5 17.2402C12.96 17.2402 13.3333 17.6197 13.3333 18.0893ZM13.8333 5.63135C13.8333 5.91325 13.61 6.14081 13.3333 6.14081H11.6667C11.39 6.14081 11.1667 5.91325 11.1667 5.63135C11.1667 5.34945 11.39 5.12274 11.6667 5.12274H13.3333C13.61 5.12274 13.8333 5.34945 13.8333 5.63135ZM17 18.7923C17 19.4479 16.4767 19.9819 15.8333 19.9819H9.16667C8.52333 19.9819 8 19.4479 8 18.7923V5.2068C8 4.55215 8.52333 4.01807 9.16667 4.01807H15.8333C16.4767 4.01807 17 4.55215 17 5.2068V18.7923ZM15.8333 3H9.16667C7.97167 3 7 3.99005 7 5.2068V18.7923C7 20.01 7.97167 21 9.16667 21H15.8333C17.0283 21 18 20.01 18 18.7923V5.2068C18 3.99005 17.0283 3 15.8333 3Z"
                      fill="white" />
              </svg>
            </div>
            <div class="d-flex align-items-center">
              <p class="font-weight-bold">Технологический лидер</p>
            </div>
          </div>
          <p>
            Решаем амбициозные задачи: развиваем мобильный банк, создали облачный колл-центр с 12 000 операторов,
            первыми в России внедрили систему распознавания голоса и лиц в реальном времени
          </p>
        </div>
        <div class="col-md-6 circle-container">
          <div class="circle"></div>
        </div>
      </div>

      <div class="row mt-32">
        <div class="col-md-4 pl-0">
          <div class="card">
            <img src="" alt="">
            <h3>Моментальная регистрация</h3>
            <p>
              Зарегистрируйся на сайте всего за минуту. Вам понадобится:
              Мобильный телефон
              Пластиковая карта
              Паспорт
            </p>
          </div>
        </div>
        <div class="col-md-8 pr-0">
          <div class="card">
            <img src="" alt="">
            <h3>Моментальная регистрация</h3>
            <p>
              Зарегистрируйся на сайте всего за минуту. Вам понадобится:
              Мобильный телефон
              Пластиковая карта
              Паспорт
            </p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import localize from '../filters/localize.filter';

import cardNew from '../components/NewsCard.vue';
import Header from '../components/Header';

import vacancyBg from '../assets/img/vacancy.png';
import cardsNew from '../data/cardNew.js';
import vacancyTypes from '../data/vacanciesData';

export default {
  components: {
    cardNew,
    Header,
  },
  data: () => ({
    cardsNew,
    vacancyBg,
    vacancyTypes,
    news: [],
    language: (localStorage.getItem('lang') === 'ru-RU' || !localStorage.getItem('lang')) ? 'ru' : 'uz',
  }),
  methods: {
    localize,
  },
};
</script>

<style lang="scss" scoped>
  .vacancies {
    background-color: $grey;
    padding-bottom: 170px;

    section.types {
      padding-bottom: 130px;
      padding-top: 80px;

      .newsCard {
        background-color: $white;
      }

      h2 {
        text-align: center;
        font-weight: 900;
        font-size: 40px;
        line-height: 40px;
        color: $black;
      }

      p {
        margin-top: 8px;
        margin-bottom: 24px;
      }
    }

    section.info {

      .tech-leader {
        .icon {
          border-radius: 8px;
        }

        .circle-container {
          position: relative;
          overflow: hidden;

          .circle {
            position: absolute;
            bottom: -50%;
            width: 404px;
            height: 404px;
            background-color: #DDDDDD;
            border-radius: 50%;
          }
        }

      }

      .card {
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        background-color: $white;
        padding: 40px 48px;

        &.min-padding {
          padding: 40px 32px;
        }
      }
    }
  }
</style>
